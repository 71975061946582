<template>
  <div class="services-content-wrapper py-8">
    <!-- <h1 class="section-title-red">Products and Services</h1> -->
    <!-- 3D Printing Services Row -->
    <v-row class="productRow pt-8">
      <v-col 
        cols="12" 
        sm="5" 
        class="d-sm-flex d-none align-center justify-center"
      >
        <v-img 
          style="max-width: 20vw"
          src="../assets/images/full-logo.png"
        >
        </v-img>
      </v-col>
      <v-col 
        cols="12" 
        sm="7"
      >
        <div class="productText">
          <h2 
            class="text-center text-sm-left" 
            style="font-size:26px"
          >
            3D Printing Services
          </h2>
          <p 
            class="pt-4" 
            style="font-size:2vh"
          >
            3D printing is a modern, innovative way of manufacturing a whole variety of items. Whether you're looking for a way to rapidly test multiple iterations of a particular part before mass production, or even producing fully finished and ready item, 3D printing is one of the best ways to make an idea real.
          </p>
        </div>
      </v-col>
    </v-row>
    <!-- 3D Modeling Services Row -->
    <v-row class="productRow py-8">
      <v-col 
        cols="12" 
        sm="7"
      >
        <div class="productText">
          <h2 
            class="text-center text-sm-left" 
            style="font-size:26px"
          >
            3D Modeling Services
          </h2>
          <p 
            class="pt-4" 
            style="font-size:2vh"
          >
            Sometimes, you may have an idea for something you want, and need some help bringing that idea to life. Together, with my 3D modeling services, we can build out a digital imprint built exactly to your specifications that will allow you to bring that item to life.
          </p>
        </div>
      </v-col>
      <v-col
        cols="12" 
        sm="5" 
        class="d-sm-flex d-none align-center justify-center"
      >
        <v-img 
          style="max-width: 15vw"
          src="../assets/images/3d-modeling.png">
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.services-content-wrapper{
  background-color: white;
}
.section-title-red {
  font-size: 40px;
  text-align: left;
  padding: 20px 0px 20px 55px;
  color: #ff064c;
  font-family: morrisanSans;
}
.productRow {
  width: 80%;
  margin: auto; 
  color: #ff064c
}
.productText {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>

<script>
  export default {
    name: 'Products',
    props: [],
    data () {
      return {
        
      }
    },
    methods: {
      
    }
  }
</script>
