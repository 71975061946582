import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Thinigiverse from '../components/CustomIcons/Thingiverse.vue'
import Printables from '../components/CustomIcons/Printables.vue'
import Etsy from '../components/CustomIcons/Etsy.vue'


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#ff064c',
        secondary: '#ffffff',
        accent: '#6B1FFF',
        warning: '#FFA306'
      }
    }
  },
  icons: {
    values: {
      thingiverse: {
        component: Thinigiverse,
      },
      printables: {
        component: Printables,
      },
      etsy: {
        component: Etsy,
      },
    },
  }
});
