<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="EG3D Logo"
          class="shrink mr-2"
          contain
          src="./assets/images/logo-white.png"
          transition="scale-transition"
          width="150"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar> -->

    <v-main
      class="pa-0"
    >
      <AppMain/>
    </v-main>
  </v-app>
</template>

<script>
import AppMain from './components/AppMain';

export default {
  name: 'App',

  components: {
    AppMain
  },

  data: () => ({
    //
  }),
};
</script>
