<template>
  <div class="about-content-wrapper">
    <div class="about-line"></div>
    <p>
      EG3D printing is a place where I can share my enjoyment of 3D printing
      with others. From the usefulness of functional prints to the
      entertainment of different printed toys and knick knacks, 3D printing
      can be a fun way to express your creativity. Take a look at some of my
      recent projects and let me know how they inspire you. Have a project
      in mind you want printed? Let me know through the links below, and
      maybe together we can make your idea a reality.
    </p>
    <div class="about-line"></div>
  </div>
</template>

<style scoped>
  .about-content-wrapper {
    margin: 0 auto;
    font-size: 2vh;
    text-align: center;
    width: 100%;
    padding: 10vh 0vw;
    color: #ff064c;
    position: relative;
    background-color: white;
  }
  .about-content-wrapper p {
    max-width: 100vw;
    padding: 5vh 10vw;
    display: inline-block;
    vertical-align: middle;
  }
  .about-content-wrapper .about-line {
    width: 15vh;
    height: 2px;
    background-color: #ff064c;
    display: inline-block;
    vertical-align: middle;
  }
</style>

<script>
  export default {
    name: 'Footer',
    props: [],
    data () {
      return {

      }
    }
  }
</script>
