<template>
  <v-container fluid class="pa-0">
    <div v-show="homeVideoBgVisible" class="video-wrapper">
      <div class="video-text">
        <h1>EG3D</h1>
        <h2>3D Printing | 3D Modeling</h2>
        <img id="down-arrow" src="../assets/images/icons/down-arrow.png" />
      </div>
      <div class="video-overlays"></div>
      <video autoplay muted loop class="myVideo">
        <source src="../assets/videos/EG3D-Home-BG-sm.mp4" type="video/mp4" />
      </video>
    </div>
    <div id="page-content">
      <About id="about-component"/>
      <Projects id="projects-component"/>
      <Products id="products-component"/>
      <Footer id="footer-component"/>
    </div>
  </v-container>
</template>

<script>
import About from "./About.vue";
import Projects from "./Projects/Projects.vue";
import Products from './Products.vue';
import Footer from './Footer.vue';

export default {
  name: "AppMain",
  components: {
    About,
    Projects,
    Products,
    Footer
  },
  data() {
    return {
      homeVideoBgVisible: true
    };
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(){
      //Handles down arrow visiblity
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos == 0) {
          document.getElementById("down-arrow").style.opacity = "1";
      } else {
          document.getElementById("down-arrow").style.opacity = "0";
      }

      //Handles video background visibilty
      var prodComp = document.getElementById("about-component");
      if (window.scrollY - window.innerHeight > (prodComp.offsetTop)) {
        this.homeVideoBgVisible = false;
      }else{
        this.homeVideoBgVisible = true;
      }
    }
  },
};
</script>

<style>
/**********************BASE**************************/
@font-face {
  font-family: morrisanSans;
  src: url("../assets/morrissansw01medium.ttf");
}
/**********************HOME**************************/
.video-wrapper h1,
h2 {
  font-family: morrisanSans;
}
.video-wrapper h1 {
  font-size: 9vh;
  line-height: 7vh;
}
.video-wrapper .sm h2 {
  font-size: 3vh;
  line-height: 7vh;
}
.video-wrapper h2 {
  font-size: 2vh;
  line-height: 7vh;
}
.video-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-attachment: scroll;
  overflow: hidden;
}
#down-arrow {
  width: 4em;
  height: 4em;
  margin: auto;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  transition: 0.5s ease;
}
.video-text {
  position: absolute;
  float: left;
  min-width: 100%;
  min-height: 100%;
  color: white;
  text-align: center;
  z-index: 3;
  padding-top: 40vh;
}
.myVideo {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-overlays {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: #ff064c;
  opacity: 0.6;
}
/**********************PAGE CONTENT**************************/
#page-content {
  position: absolute;
  top: 100vh;
  height: auto;
  width: 100%;
}
/**********************Contact**************************/
.contact-content-wrapper {
  width: 100%;
  background-color: #ff064c;
}

</style>
