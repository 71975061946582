<template>
  <div class="projects-content-wrapper py-8">
    <!-- <h1 class="section-title">Projects</h1> -->
    <!-- EG3D Designs Row -->
    <v-row class="productRow">
      <v-col cols="12">
        <div class="productText">
          <h2 
            class="text-center" 
            style="font-size:26px"
          >
            Projects
          </h2>
          <p 
            class="pt-4 text-center" 
            style="font-size:2vh"
          >
            A collection of 3D printing projects I've completed. All of these and more are available to be printed in whatever size or color you can think of.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-container class="projectsContainer px-5" style="min-width: 100%; margin: 0 auto;">
      <v-row v-masonry >
        <v-col
          cols="6"
          sm="3"
          v-for="(item, i) in items"
          :key="i"
          class="pa-2"
        >
          <v-hover v-slot:default="{ hover }">
            <v-img 
              eager 
              :src="item.images[0]" 
              @click="clickProject(item)" 
              @load="$redrawVueMasonry('projectsContainer')"
              :style="{opacity: hover ? 0.8 : 1}"
            ></v-img>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog 
      v-model="projectDetailDialogVisible" 
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="80%"
    >
      <ProjectDetail
       :currentViewedProject="currentViewedProject"
       @closeDialog="projectDetailDialogVisible = false;" />
    </v-dialog>
  </div>
</template>

<style scoped>
.projects-content-wrapper {
  width: 100%;
  background-color: #ff064c;
  padding: 60px 0px 0pz 0px;
}
.section-title {
  font-size: 40px;
  text-align: left;
  padding: 20px 0px 20px 55px;
  color: white;
  font-family: morrisanSans;
}
.productRow {
  width: 80%;
  margin: auto; 
  color: white
}
.productText {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

</style>

<script>
import ProjectDetail from "./ProjectDetail.vue";

  export default {
    name: 'Projects',
    props: [],
    components: {
      ProjectDetail,
    },
    data () {
      return {
        projectDetailDialogVisible: false,
        currentViewedProject: null,
        items: [
          {
            images: [
              require("../../assets/images/projects/guitar-body.jpg")
            ],
            title: "3D Printed Guitar Body",
            description: "This guitar is one of the most complex projects I've done to date. The guitar body comes from Conceptor on Thingiverse and was printed in 6 pieces, fastened together with a strong epoxy. After that, many hours of sanding, priming, more sanding, body filling, and still more sanding to get it to a perfectly smooth surface for paint. Overall, the guitar came out great and is a unique take on a classic Fender Stratocaster.",
            source: "https://www.thingiverse.com/thing:3174465"
          },
          {
            images: [
              require("../../assets/images/projects/bass-guitar-body.jpg")
            ],
            title: "3D Printed Bass Guitar Body",
            description: "After I finished printing and assembling the honeyhcomb stratocaster, I wanted to test my skills in modeling a similarly-styled bass guitar body. Using only a few dimensions of basic parts and reference images, I was able to sketch up a bass body that would work. I also took the opportunity to add a few additional quality-of-life upgrades to the model such as built in pegs for alignment during glue-up.",
            source: "https://www.printables.com/model/86853-hexagonal-precision-bass-guitar"
          },
          {
            images: [
              require("../../assets/images/projects/water-droplet.jpg"),
              require("../../assets/images/projects/water-droplet-new.jpg"),
            ],
            title: "Water Droplet Kinectic Toy",
            description: "This water droplet kinetic toy has to be one of the more complex models I've designed. Based on a design by Dean O'Callaghan, I modeled this up to test out my ability to create something that used mechanics to create a tactile object that is more than just a neat shelf piece.",
            source: "https://www.printables.com/model/124962-new-water-droplet-kinetic-desk-toy"
          },
          {
            images: [
              require("../../assets/images/projects/ls3-engine-complete.jpg"),
              require("../../assets/images/projects/ls3-engine.jpg"),
            ],
            title: "Chevy LS3 Engine",
            description: "As a Chevy guy my whole life, this LS3 engine model by ericthepoolboy on Thingiverse was a must-print as soon as I saw it. The variety of different parts that make up this model allowed me to test my printer to its limits. Small parts like the valve heads and rocker arms let me work with printing dozens of small pieces at the same time, while the engine block itself was endurance test, taking more than 40 hours of continuous printing!",
            source: "https://www.thingiverse.com/thing:1911808"
          },
          {
            images: [
              require("../../assets/images/projects/digi-dash.jpg")
            ],
            title: "Digi-Dash",
            description: "This was one of the first multi-part projects I worked on. For a software engineering class I took, my group and I were tasked with creating a software product from the ground up. We decided to go with attempting to produce an open-source dashboard and vehicle monitoring system using a raspberry pi computer. In order to make the project look more appealing, I figured it would be best to make a case for the raspberry pi and its components, and I found the best way of doing this was with our lab's 3D printer. This was my first foray into 3D printing. After a little trial and error and success, I decided it was time to buy my own 3D printer and I haven;t stopped making anything since!",
            source: "https://www.thingiverse.com/thing:1585924"
          },
          // {
          //   images: [
          //     require("../../assets/images/projects/IMG_20161207_125818.jpg")
          //   ],
          //   title: "Call of Duty Ray Gun",
          //   description: "",
          //   source: "https://www.thingiverse.com/thing:3647949"
          // },
          {
            images: [
              require("../../assets/images/projects/tedx-buffalo.jpg")
            ],
            title: "TEDx Buffalo Nameplates",
            description: "These nameplates were some of the first commissioned models I sketched up and printed. These were given to the speakers at TEDx Buffalo.",
          },
          {
            images: [
              require("../../assets/images/projects/wankel.jpg")
            ],
            title: "Wankel Rotary Model",
            description: "A simple model of a wankel rotary engine printed in many colors to distiguish between the different parts of a rotary engine. Printed this way, it's a great tool to teach about engine mechanics.",
            source: "https://www.thingiverse.com/thing:170320"
          },
          {
            images: [
              require("../../assets/images/projects/destiny-ghost.jpg")
            ],
            title: "Destiny Ghost",
            description: "This model of a Ghost from the game Destiny is designed by BoldPrintShop. This model is great because it's not only just a simple model, but a multi-part model attached by magnets so that you can print multiple different colors of each piece and customize it as you see fit. Not only that, but it has a built-in slot for an LED tealight which allows the central eye to glow just like in the game.",
            source: "https://www.thingiverse.com/thing:527736"
          },
        ],
      }
    },
    mounted() {
      setTimeout(() => this.$redrawVueMasonry(), 1500);
      console.log(this.$vuetify.breakpoint)

    },
    watch: {
      items: function() {
        setTimeout(() => this.$redrawVueMasonry(), 1500);
      }
    },
    methods: {
      clickProject(item) {
        this.projectDetailDialogVisible = true;
        this.currentViewedProject = item;
      },
    }
  }
</script>
