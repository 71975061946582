<template>
  <v-card
    color="#ff064c"
  >
    <v-row class="pa-0 ma-0">
      <v-col class="d-flex justify-end">
        <v-btn
          icon
          large
          @click="$emit('closeDialog')"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="px-md-16 pa-4 pt-4 pb-16" style="margin:auto;">
      <v-row>
        <v-col v-if="$vuetify.breakpoint.smAndDown" class="pa-0">
          <h1 class="pb-md-16 pa-4" style="font-size:3vh;text-align:center;color:white;font-family:morrisanSans">{{currentViewedProject.title}}</h1>
        </v-col>
        <v-col cols="12" md="5" class="d-flex align-center">
          <v-carousel 
            v-model="selectedCarousel"
            :show-arrows="currentViewedProject.images.length > 1"
            hide-delimiters
            height="auto"
          >
            <v-carousel-item
              v-for="(img, i) in currentViewedProject.images"
              :key="i"
            >
              <v-img :src="img"></v-img>
            </v-carousel-item>
          </v-carousel>
          <!-- <v-img :src="currentViewedProject.images[0]" style="width:40vh"></v-img> -->
        </v-col>
        <v-col cols="12" md="7">
          <v-row v-if="!$vuetify.breakpoint.smAndDown">
            <v-col cols="12">
              <h1 class="pb-md-16 pa-4" style="font-size:3vh;text-align:center;color:white;font-family:morrisanSans">{{currentViewedProject.title}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="px-md-8 px-4" style="color:white;text-align:center;font-size:2vh">
                {{currentViewedProject.description}}
              </p>
            </v-col>
          </v-row>
          <v-row v-if="currentViewedProject.source != null">
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="accent"
                :href="currentViewedProject.source"
                target="_blank"
              >Source</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'ProjectDetail',
    props: ['currentViewedProject'],
    data () {
      return {
        selectedCarousel: null,
      }
    }
  }
</script>
