<template>
  <div class="contact-content-wrapper py-8">
    <v-row style="width: 100%" class="ma-0">
      <v-col cols="12" class="pt-8">
        <h2 
          class="text-center" 
          style="font-size:2vh"
        >
          See something you like, or have something in mind you want 3D printed?
        </h2>
      </v-col>
    </v-row>
    <v-row class="contact-row" style="margin: 0 auto;">
      <v-col 
        cols="12" 
        sm="6"
        class="pt-4"
      >
        <h2 
          class="text-center" 
          style="font-size:3vh;"
        >
          Contact
        </h2>
        <p 
          class="text-center pt-8 mb-0" 
          style="font-size:1.8vh;"
        >
          eg3dprinting@gmail.com
        </p>
      </v-col>
      <v-col cols="12" sm="6">
        <h2 
          class="text-center" 
          style="font-size:3vh;"
        >
          Links
        </h2>
        <div class="d-flex justify-center align-center pt-8">
          <v-btn
            href="https://twitter.com/EG3DPrinting"
            icon
            target="_blank"
            x-large 
            class="mx-sm-4 mx-1"
          >
            <v-icon
              x-large 
              color="white"
            >mdi-twitter</v-icon>
          </v-btn>
          <v-btn
            href="https://www.instagram.com/eg3dprinting/"
            icon
            target="_blank"
            x-large
            class="mx-sm-4 mx-1"
          >
            <v-icon
              x-large 
              color="white"
            >mdi-instagram</v-icon>
          </v-btn>
          <v-btn
            href="https://www.youtube.com/channel/UCNBRiMN5IuaKhzjvcWoUwGg"
            icon
            target="_blank"
            x-large
            class="mx-sm-4 mx-1"
          >
            <v-icon
              x-large 
              color="white"
            >mdi-youtube</v-icon>
          </v-btn>
          <v-btn
            href="https://www.thingiverse.com/eg3dprinting"
            icon
            target="_blank"
            x-large
            class="mx-sm-4 mx-1"
          >
            <v-icon
              x-large 
              color="white"
            >$thingiverse</v-icon>
          </v-btn>
          <v-btn
            href="https://www.printables.com/social/21369-eg3dprinting"
            icon
            target="_blank"
            x-large
            class="mx-sm-4 mx-1"
          >
            <v-icon
              x-large 
              color="white"
            >$printables</v-icon>
          </v-btn>
          <v-btn
            href="https://www.etsy.com/shop/EG3D"
            icon
            target="_blank"
            x-large
            class="mx-sm-4 mx-1"
          >
            <v-icon
              large 
              color="white"
            >$etsy</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
  .contact-content-wrapper{
    background-color: #ff064c;
    color:white;
  }
  .contact-row {
    width: 60%;
    padding: 75px 0px;
  }
</style>

<script>
  export default {
    name: 'Footer',
    props: [],
    data () {
      return {
        
      }
    }
  }
</script>
